import React from "react";
import styles from '../css/Tos.module.css';

function Tos() {
    return (
        <div className={styles.container}>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>1. </strong>Attempting to bypass the code of this website in any way will be considered as cheating.
            </p>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>2. </strong>You must be 18+ or have an adult give you permission to participate.
            </p>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>3. </strong>We will attempt to give the winner their prize within one week of winning. 
                Via email. Should they fail to respond, the prize will be given to the next runner up or to a randomly selected Charity.
            </p>
            <p className={styles.ruleText}>
                <strong className={styles.ruleTitle}>4. </strong>Do not use ad blockers.
            </p>
        </div>
    );
}

export default Tos;
