import React, { useEffect, useRef, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import '../css/Slots.css';
import Styles from "../css/Auth.module.css";
import { Link } from 'react-router-dom';

const iconMap = ["seven", "cherry", "pulm", "orange", "bell", "bar", "lemon", "melon", "banana"];
const iconWidth = 79;
const iconHeight = 79;
const numIcons = 9;
const timePerIcon = 100;

const Slots = () => {
  const [indexes, setIndexes] = useState([0, 0, 0]);
  const [isRolling, setIsRolling] = useState(false);
  const [coins, setCoins] = useState(null);
  const debugEl = useRef(null);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchCoins = async (user) => {
      console.log(user.uid)
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      console.log(userDoc)
      if (userDoc.exists()) {
        setCoins(userDoc.data().coins);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchCoins(user);
      } else {
        setCoins(null);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const roll = (reel, offset = 0) => {
    const delta = (offset + 2) * numIcons + Math.round(Math.random() * numIcons);

    return new Promise((resolve) => {
      const style = getComputedStyle(reel);
      const backgroundPositionY = parseFloat(style["background-position-y"]);
      const targetBackgroundPositionY = backgroundPositionY + delta * iconHeight;
      const normTargetBackgroundPositionY = targetBackgroundPositionY % (numIcons * iconHeight);

      setTimeout(() => {
        reel.style.transition = `background-position-y ${(8 + 1 * delta) * timePerIcon}ms cubic-bezier(.41,-0.01,.63,1.09)`;
        reel.style.backgroundPositionY = `${backgroundPositionY + delta * iconHeight}px`;
      }, offset * 150);

      setTimeout(() => {
        reel.style.transition = `none`;
        reel.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
        resolve(delta % numIcons);
      }, (8 + 1 * delta) * timePerIcon + offset * 150);
    });
  };

  const rollAll = async () => {
    if (coins <= 0 || isRolling) return;

    setIsRolling(true);

    const reelsList = document.querySelectorAll('.slots > .reel');

    Promise.all(
      [...reelsList].map((reel, i) => roll(reel, i))
    ).then(async (deltas) => {
      const newIndexes = [...indexes];
      deltas.forEach((delta, i) => newIndexes[i] = (newIndexes[i] + delta) % numIcons);
      setIndexes(newIndexes);

      if (debugEl.current) {
        debugEl.current.textContent = `Result: ${newIndexes.map((i) => iconMap[i]).join(' - ')}`;
        console.log(`Indexes: [${newIndexes}]`, `Result: ${newIndexes.map((i) => iconMap[i]).join(' - ')}`);
      }

      const counts = newIndexes.reduce((acc, index) => {
        const symbol = iconMap[index];
        acc[symbol] = (acc[symbol] || 0) + 1;
        return acc;
      }, {});

      let coinsWon = 0;
      if (counts["seven"] === 3) coinsWon = 60;
      else if (counts["seven"] === 2) coinsWon = 30;
      else if (counts["bar"] === 3) coinsWon = 40;
      else if (counts["bar"] === 2) coinsWon = 20;
      else if (counts["bell"] === 3) coinsWon = 20;
      else if (counts["bell"] === 2) coinsWon = 10;
      else if (Object.values(counts).some(count => count === 3)) coinsWon = 12;
      else if (Object.values(counts).some(count => count === 2)) coinsWon = 6;

      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            coins: (coins || 0) + coinsWon
          });
          setCoins((coins || 0) + coinsWon);
        }
      } catch (error) {
        console.error("Error updating coins:", error);
      }

      setIsRolling(false);
    });
  };

  return (
    <div>
      <div className={Styles.coins}>Coins: {coins !== null ? coins : 'Loading...'}</div>
      <div className="slots">
        <div className="reel"></div>
        <div className="reel"></div>
        <div className="reel"></div>
      </div>
      <button 
        onClick={rollAll} 
        id='rollButton' 
        disabled={isRolling || coins > 0} 
        className={Styles.button}
      >
        <a>{isRolling ? 'Rolling...' : 'Roll'}</a>
      </button>
      <Link to="/guess"><button className={Styles.button}>Guess</button></Link>
      <div id="debug" className="debug" ref={debugEl}></div>
    </div>
  );
};

export default Slots;