import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Styles from "../css/Auth.module.css";

const Guess = () => {
  const [secretString, setSecretString] = useState('');
  const [result, setResult] = useState('');
  const [generatedString, setGeneratedString] = useState('');
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchSecretString = async (user) => {
      try {
        const userDoc = await getDoc(doc(db, 'game', "secretstring"));
        if (userDoc.exists()) {
          const secret = userDoc.data().secret || '';
          setSecretString(secret);
        } else {
        }
      } catch (error) {
        console.error("Error fetching secret string:", error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchSecretString(user);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < 8; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  };

  const checkMatch = () => {
    const generated = generateRandomString();
    setGeneratedString(generated);

    if (generated === secretString) {
      setResult('🎉 Correct Guess! You matched the secret phrase!');
      console.log('Result: Correct Guess!'); // Log the result for debugging
    } else {
      setResult('❌ Incorrect guess. Try again!');
      console.log('Result: Incorrect guess.'); // Log the result for debugging
    }
  };

  return (
    <div>
      <button onClick={checkMatch} className={Styles.button}>
        Generate & Check
      </button>
      <div className="generated" style={{ color: "var(--text)" }}>Generated Code: {generatedString || 'Press the button to generate a code'}</div>
      <div className="result" style={{ color: "var(--text)" }}>{result}</div>
    </div>
  );
};

export default Guess;
