import styles from "../css/Tos.module.css"

function Pp() {
    return(<>
        return (
        <div className={styles.container}>
            <h1 className={styles.title}>Terms of Service</h1>
            <p>
                At <strong>Volceria</strong>, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your email address.
            </p>
            
            <h2 className={styles.subheading}>1. Information We Collect</h2>
            <p>We collect only one type of personal information:</p>
            <ul>
                <li>Email Address: When you provide your email address on our website, we store it solely for the purpose of communicating with you.</li>
            </ul>
            
            <h2 className={styles.subheading}>2. How We Use Your Information</h2>
            <p>We use the email address you provide solely for the following purposes:</p>
            <ul>
                <li>To respond to your inquiries or requests.</li>
                <li>To send important updates related to the website, services, or changes to this privacy policy.</li>
            </ul>
            <p>We will not use your email address for any other purposes unless required by law or in the event of an emergency.</p>
            
            <h2 className={styles.subheading}>3. Sharing Your Information</h2>
            <p>We do not sell, rent, or share your email address with third parties. We may share your email address with trusted service providers who assist in operating our website or in conducting business (e.g., email delivery services), but only for the purpose of fulfilling their tasks on our behalf. These service providers are obligated not to disclose or use your information for any other purpose.</p>
            
            <h2 className={styles.subheading}>4. Data Security</h2>
            <p>We take reasonable steps to protect the email addresses we collect from unauthorized access, use, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>
            
            <h2 className={styles.subheading}>5. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access the email address you’ve provided and request corrections if necessary.</li>
                <li>Request the deletion of your email address from our records.</li>
            </ul>
            <p>To exercise these rights, please contact us at [Insert Contact Email].</p>
            
            <h2 className={styles.subheading}>6. Cookies and Tracking</h2>
            <p>Our website may use cookies or similar tracking technologies to enhance your experience. However, these cookies will not collect any personally identifiable information such as your email address.</p>
            
            <h2 className={styles.subheading}>7. Children’s Privacy</h2>
            <p>Our services are not intended for children under the age of 13. We do not knowingly collect or maintain information from children under 13. If we learn that we have inadvertently collected personal information from a child under 13, we will delete that information as soon as possible.</p>
            
            <h2 className={styles.subheading}>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
            
            <h2 className={styles.subheading}>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at [Insert Contact Email].</p>
        </div>
    );
    </>)
}

export default Pp