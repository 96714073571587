// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXhI4_SWFnSnHcVB9u9N8hvaK1rK6bcDA",
  authDomain: "volceria.firebaseapp.com",
  projectId: "volceria",
  storageBucket: "volceria.firebasestorage.app",
  messagingSenderId: "348455997689",
  appId: "1:348455997689:web:2094ea468a4d1fc2491228",
  measurementId: "G-YLWQ13ENVH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app); // Initialize Firestore and export it
