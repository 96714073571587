import Slots from "./components/Slots"
import AuthPage from "./components/AuthForm";
import {useAuthState} from "react-firebase-hooks/auth"
import {auth} from "./firebaseConfig";
import Home from "./components/Home"
import EmailVerify from "./components/EmailVerify"
import {Routes, Route} from "react-router-dom"
import Guess from "./components/Guess";
import About from "./components/About";
import Tos from "./components/Tos";
import Pp from "./components/Pp";
import Footer from "./components/subComponents/footer";
function App() {
  const [user] = useAuthState(auth)
  return (
<>
<Routes >
{user ? (
          user.emailVerified ? (
            <Route path="/" element={<Slots />} />
          ) : (
            <Route path="/" element={<EmailVerify />} />
          )
        ) : (
          <Route path="/" element={<Home />} />
        )}

      
      <Route path="/signin" element={<AuthPage/>}/>
      <Route path="/guess" element={<Guess/>}/>
      <Route path="/tos" element={<Tos/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/pp" element={<Pp/>}/>
    </Routes>
      <Footer />
      </>

    
  );
}

export default App;
