import React, { useState, useEffect, useRef } from "react";
import {useAuthState} from "react-firebase-hooks/auth"
import {auth} from "../firebaseConfig";
import Styles from "../css/Auth.module.css"; // Make sure your styles handle canvas behind the form
import { Link } from "react-router-dom";
import Footer from "./subComponents/footer";



const ParticleCanvas = () => {
  const canvasRef = useRef(null);
  const particles = useRef([]);

  const resizeCanvas = (canvas, context) => {
    const oldWidth = canvas.width;
    const oldHeight = canvas.height;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  
    particles.current.forEach((particle) => {
      particle.x = (particle.x / oldWidth) * canvas.width;
      particle.y = (particle.y / oldHeight) * canvas.height;
    });
  };
  

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const createParticles = () => {
      for (let i = 0; i < 100; i++) {
        particles.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          size: Math.random() * 2 + 1,
          opacity: Math.random() * 0.4 + 0.1,
          speed: Math.random() * 1.2 + 1,
          direction: Math.random() < 0.5 ? 1 : -1,
        });
      }
    };

    const drawParticle = (particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(255, 255, 255, ${particle.opacity})`;
      ctx.fill();
    };

    const animateParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.current.forEach((particle) => {
        particle.y += particle.direction * particle.speed;
        particle.x += Math.random() * 2 - 1;

        if (particle.direction === 1 && particle.y > canvas.height + particle.size) {
          particle.y = -particle.size;
          particle.x = Math.random() * canvas.width;
        } else if (particle.direction === -1 && particle.y < -particle.size) {
          particle.y = canvas.height + particle.size;
          particle.x = Math.random() * canvas.width;
        }

        drawParticle(particle);
      });

      requestAnimationFrame(animateParticles);
    };

    createParticles();
    animateParticles();

    resizeCanvas(canvas, ctx);

    window.addEventListener("resize", () => resizeCanvas(canvas, ctx));

    return () => {
      window.removeEventListener("resize", () => resizeCanvas(canvas, ctx));
    };
  }, []);

  return <canvas ref={canvasRef} id="particle-canvas"></canvas>;
};

const Home = () => {


  return (
    <div className={Styles["auth-page"]}>
      {/* Add the ParticleCanvas component here */}
      <ParticleCanvas />

      <div
        className={`${Styles.card} `}
      >
        {/* Front Side: Sign In */}
        <div className={`${Styles.front}`} style={{ textAlign: "center", color: "var(--primary)" }}>
            <h1>Email Verification requeired</h1>
            <p>Please click the link that was send to your email address. Make sure to check spam/junk folder</p>
        </div>
        </div>
        <Footer />
      </div>
  );
};

export default Home;
