import React from "react";
import styles from '../css/Tos.module.css';

function About() {
    return (
        <div className={styles.container}>
            <p className={styles.mainText}>
                This game was set up to grant people the opportunity to exercise their luck or skill in guessing the password to win
                <strong className={styles.highlight}> AND IS ENTIRELY FREE TO PLAY!</strong>
            </p>
            <p className={styles.subText}>
                It is not entirely a game of chance, and we look forward to you all utilising whatever methods you can to win! 
                <span className={styles.clueText}> Who knows, maybe there are clues you could find.</span>
            </p>
        </div>
    );
}

export default About;
